<template>
  <div class="page-about">
    <h1>About page</h1>
  </div>
</template>

<script>


export default {
  name: "PageAbout"
};
</script>
